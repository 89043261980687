import { Controller } from "stimulus"
import Chart from 'chart.js/auto'

export default class extends Controller {
  static targets = ['canvas']
  static values = {
    type: {
      type: String,
      default: 'line'
    },
    data: Object,
    options: Object
  }

  connect () {
    const image = new Image();
    image.src = '/images/logo.png';

    const plugin = {
      id: 'customCanvasBackgroundImage',
      beforeDraw: (chart) => {
        if (image.complete) {
          const ctx = chart.ctx;
          const { top, left, width, height } = chart.chartArea;
          const x = left + width / 2 - image.width / 2;
          const y = top + height / 2 - image.height / 2;
          ctx.drawImage(image, x, y);
        } else {
          image.onload = () => chart.draw();
        }
      }
    };

    const element = this.hasCanvasTarget ? this.canvasTarget : this.element

    this.chart = new Chart(element.getContext('2d'), {
      type: this.typeValue,
      data: this.dataValue,
      plugins: [plugin]
    })
  }

  disconnect () {
    this.chart.destroy()
    this.chart = undefined
  }
}
