<template>
    <label :for="$props['for']">
        <slot></slot>
        <Tooltip v-if="tooltip_text" :title="tooltip_text"></Tooltip>
    </label>
</template>


<script>
import Tooltip from "./tooltip.vue";

export default {
  components: { Tooltip },
  inheritAttrs: false,
  props: {
    for: {
      type: String,
      require: true
    },
    tooltip_text: {
      type: String,
    },
  },
}
</script>