<template>
  <div id="interest_position_type__wrapper" class="d-flex align-items-center">
    <label class="switch_toggle" for="toggle_button">
      <input type="hidden" :name="name" v-model="inputValue"/>

      <input class="switch_toggle__input_button" type="checkbox" id="toggle_button" :checked="inputValue === on_value" v-on:click="toggleInputValue">
      <span class="switch_toggle__slider-button"></span>
    </label>
    <p class="mb-0">{{ inputLabel }}</p>
  </div>
</template>

<script>
export default {
  props: {
    name: {
      type: String,
      require: true
    },
    value: {
      type: String,
      require: true
    },
    on_value: {
      type: String,
      require: true
    },
    on_label: {
      type: String,
      require: true
    },
    off_value: {
      type: String,
      require: true
    },
    off_label: {
      type: String,
      require: true
    }
  },

  methods: {
    toggleInputValue: function () {
      if (this.inputValue === this.$props.on_value) {
        this.inputValue = this.$props.off_value
      } else {
        this.inputValue = this.$props.on_value
      }
    }
  },

  computed: {
    inputValue: {
      get() {
        let value = this.$store.getters.getValue(this.$props.name)
        if (value === true || value === this.$props.on_value) {
          return this.$props.on_value
        } else {
          return this.$props.off_value
        }
      },
      set(value) {
        this.$store.dispatch('update', {
              value: (value === true || value === this.$props.on_value) ? this.$props.on_value : this.$props.off_value,
              name: this.$props.name
            }
        )
      }
    },

    inputLabel: {
      get() {
        let value = this.$store.getters.getValue(this.$props.name)
        if (value === true || value === this.$props.on_value) {
          return this.$props.on_label
        } else {
          return this.$props.off_label
        }
      }
    }
  },
}
</script>