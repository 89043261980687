<template>
  <span ref='tooltip' :data-placement="placement" :title="title">
    <i class="fa fa-info-circle" aria-hidden="true"></i>
  </span>

</template>


<script>
import {Tooltip} from 'bootstrap'
export default {
  inheritAttrs: false,
  props: {
    title: {
      type: String,
      require: true
    },
    placement: {
      type: String,
      require: true,
      default: 'top'
    }
  },
  mounted: function() {
    this.$nextTick(function () {
      new Tooltip(this.$refs.tooltip)
    })
  }
}
</script>