import { Controller } from "stimulus"

export default class extends Controller {

    static targets = ["toggle"];
    static values = {
        botyglot: Boolean
    }

    connect() {
        this.botyglotValue === true ? this.toggleTarget.checked = true
            : this.toggleTarget.checked = false;
    }

    changeParam(mode, status) {
        let queryParams = new URLSearchParams(window.location.search);

        queryParams.set("botyglot_mode", mode);
        history.pushState(null, null, "?" + queryParams.toString());
        this.botyglotValue = status;
        location.reload();
    }

    toggleDm(_event) {
        let dmParams = window.location.href;

        // If param already exist
        dmParams.indexOf("botyglot_mode") > -1 ? this.toggleTarget.checked
         ? this.changeParam("1", true) // url.set("botyglot_mode", "1")
         : this.changeParam("0", false)
        // If param does not exist
         : this.toggleTarget.checked
         ? window.location.search += "botyglot_mode=1"
         : window.location.search += "botyglot_mode=0";
    }
}