<template>
    <div :class="inputGroupClass" :id="id + '__wrapper'">
        <div v-if="prepend" class="input-block__prepend" :id="id + '__prepend'">
            <span v-html="prepend"></span>
        </div>

        <input type="hidden" :name="name" :value="inputValue"/>

        <flat-pickr
                ref="input"
                v-bind="this.$attrs"
                v-model="inputFormattedValue"
                :id="id"
                :class="[
            inputClass,
            prepend ? 'input--has-prepend' : '',
             append ? 'input--has-append' : '',
             ]"
                :config="config"
                @on-open="onFocusDatePicker"
        ></flat-pickr>

        <div v-if="append" class="input-block__append" :id="id + '__append'">
            <span v-html="append"></span>
        </div>

        <span :id="id + '__error_message'" v-if="displayValidationError" class="input-block__error-feedback">{{ inputError }}</span>
        <span :id="id + '__warning_message'" v-if="displayValidationWarning" class="input-block__warning-feedback">{{ inputWarning }}</span>
    </div>

</template>


<script>
import { debounce } from "lodash";
import { formatISO, isValid, parse, isMatch, parseISO } from "date-fns";
import { format, utcToZonedTime, zonedTimeToUtc } from "date-fns-tz";
import flatPickr from "vue-flatpickr-component";
import ConfirmDatePlugin from 'flatpickr/dist/plugins/confirmDate/confirmDate';
import 'flatpickr/dist/plugins/confirmDate/confirmDate.css';
import "flatpickr/dist/flatpickr.css";
import * as R from 'ramda'

export default {
  data () {
    return {
      config: {
        dateFormat: this.pickerFormat(),
        allowInput: true,
        enableTime: true,
        onOpen: function (selectedDates, dateStr, instance) {
          if (this.default_datetime && instance.input.value == '') {
            instance.setDate(this.default_datetime);
          }
        }.bind(this.$props),
        time_24hr: true,
        minuteIncrement: this.minute_increment,
        "plugins": [
          new ConfirmDatePlugin({
            confirmText: "Done",
            showAlways: true,
            theme: 'light'
          })
        ]
      },
      debouncedSetFormattedValue: debounce(this.setFormattedValue, 500),
    };
  },
  components: {
    flatPickr,
  },
  inheritAttrs: false,
  props: {
    append: {
      type: String,
    },
    name: {
      type: String,
      require: true,
    },
    id: {
      type: String,
      require: true,
    },
    prepend: {
      type: String,
    },
    suggest_value: {
      type: String,
      require: false,
      default: "false",
    },
    display_format: {
      type: String,
      require: false,
      default: "dd/MM/yyyy HH:mm",
    },
    time_zone: {
      type: String,
      require: true,
    },
    default_datetime: {
      type: String,
      require: false,
    },
    minute_increment: {
      type: String,
      require: false,
      default: '5'
    }
  },
  computed: {
    displayValidationError: function () {
      return this.inputTouched && this.inputError;
    },
    displayValidationWarning: function () {
      return this.inputTouched && !this.inputError && this.inputWarning;
    },
    suggestValue: function () {
      return this.$props.suggest_value === "true";
    },
    useSuggestedValue: function () {
      return this.suggestValue && !this.inputTouched;
    },
    suggestedValue: function () {
      return this.$store.getters.getSuggestedValues(this.$props.name);
    },
    inputClass: function () {
      return {
        "input-block__field": true,
        "input-block__field--invalid": this.displayValidationError,
        datepicker: true,
      };
    },
    inputError: function () {
      if (this.inputTouched) {
        return this.$store.getters.getError(this.$props.name);
      } else {
        return null;
      }
    },
    inputWarning: function () {
      if (this.inputTouched) {
        return this.$store.getters.getWarning(this.$props.name);
      } else {
        return null;
      }
    },
    inputGroupClass: function () {
      return {
        "input-block": true,
      };
    },
    inputTouched: {
      get () {
        return this.$store.getters.getTouched(this.$props.name);
      },
      set (value) {
        this.$store.commit("setTouched", {
          value: value,
          name: this.$props.name,
        });
      },
    },
    inputValue: {
      get () {
        return this.$store.getters.getValue(this.$props.name);
      },
      set (value) {
        this.$store.dispatch("update", {
          value: value,
          name: this.$props.name,
        });
      },
    },
    inputFormattedValue: {
      get () {
        let value = this.$store.getters.getValue(this.$props.name)
        let tempDate = value == null ? "" : new Date(value);
        if (isValid(tempDate)) {
          // it is safe to parse Value using utcToZonedTime
          return format(
            utcToZonedTime(value, this.$props.time_zone),
            this.$props.display_format
          );
        } else {
          return "";
        }
      },
      set (value) {
        // user is probably typing a new date, wait for him to finish
        // and avoid unnecessary updates of the state
        this.debouncedSetFormattedValue(value)
      },
    },
  },
  watch: {
    suggestedValue: function (value, _oldValue) {
      if (this.useSuggestedValue) {
        this.inputValue = value;  // :fixme : make sure this really works with the timezones and the right format
      }
    },
  },
  methods: {
    onFocusDatePicker: function () {
      this.inputTouched = true;
    },
    pickerFormat: function () {
      let format;
      if (this.$props.display_format === 'dd MMM yy HH:mm') {
        format = 'j M y H:i'
      } else if (this.$props.display_format === 'dd/MM/yyyy HH:mm') {
        format = 'd/m/Y H:i';
      } else {
        format = 'd/m/Y H:i';
      }
      return format
    },
    setFormattedValue: function (value) {
      let display_format = this.$props.display_format
      if (!R.isNil(value) && !R.isEmpty(value) && !isMatch(value, display_format)) {
        // ignore non-blank & unmatched values
        // user is probably typing a new date, wait for him to finish
        return
      }
      let date = parse(value, display_format, this.getCurrentDateInTimezone());
      let normalised_date = isValid(date) ? formatISO(zonedTimeToUtc(date, this.$props.time_zone)) : ''
      this.$store.dispatch("update", {
        value: normalised_date,
        name: this.$props.name,
      });
    },
    getCurrentDateInTimezone: function () {
      function zeroPad (num, places) {
        var zero = places - num.toString().length + 1;
        return Array(+(zero > 0 && zero)).join("0") + num;
      }

      // construct the date in UTC (ex: 2014-06-25T10:00:00.000Z)
      let date = new Date()
      let year = zeroPad(date.getUTCFullYear(), 4)
      let month = zeroPad(date.getUTCMonth() + 1, 2)
      let day = zeroPad(date.getUTCDate(), 2)
      let hour = zeroPad(date.getUTCHours(), 2)
      let minute = zeroPad(date.getUTCMinutes(), 2)
      let second = zeroPad(date.getUTCSeconds(), 2)
      let dateString = `${year}-${month}-${day}T${hour}:${minute}:${second}.000Z`
      // convert to timezone
      let result = utcToZonedTime(dateString, this.$props.time_zone)
      return result
    }
  },
};
</script>
