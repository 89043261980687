import {Controller} from "stimulus"

export default class extends Controller {
  static targets = ["copyText", "inputText"]

  connect() {
  }

  copy() {
    this.inputTextTarget.select();
    document.execCommand("copy");

    this.copyTextTarget.classList.add("active");
    window.getSelection().removeAllRanges();


    setTimeout(() => {
      this.copyTextTarget.classList.remove("active");
    }, 2500);
  }
}