import {Controller} from "stimulus"
import parseISO from 'date-fns/parseISO'
import formatISO from 'date-fns/formatISO'
import {formatInTimeZone} from 'date-fns-tz'

export default class extends Controller {
  static targets = [
    "clock",
    "clockText",
    "clockTimeElement",
    "menu",
    "menuOverlay"
  ]
  static values = {
    selectedCity: Object,
    serverTime: String,
    browserDelta: Number,
  }

  connect() {
    this.browserDeltaValue = new Date() - parseISO(this.serverTimeValue)
    this.hideMenu()

    this.refresher = setInterval(() => {
      this.calculateServerTime()
    }, 5000)
  }

  disconnect() {
    this.stopRefreshing()
  }

  // clear interval
  stopRefreshing() {
    if (this.refresher) {
      clearInterval(this.refresher)
    }
  }

  calculateServerTime() {
    let estimatedServerTime = new Date(new Date() - this.browserDeltaValue)
    this.serverTimeValue = formatISO(estimatedServerTime)
  }

  selectedCityValueChanged(value, _previousValue) {
    this.updateClocks()
  }

  serverTimeValueChanged(value, _previousValue) {
    // console.log("this.serverTimeValue", value)
    this.updateClocks()
  }

  // **** helpers ****

  // update all targets
  updateClocks() {
    this.clockTarget.innerHTML = this.formattedServerTime(this.selectedCityValue.time_zone)
    this.clockTextTarget.innerHTML = this.selectedCityValue.name

    this.clockTimeElementTargets.forEach(el => {
      el.innerHTML = this.formattedServerTime(el.dataset.timeZone)
    })
  }


  // display the server time in the time_zone passed as argument
  formattedServerTime(time_zone) {
    const serverTime = parseISO(this.serverTimeValue);
    return formatInTimeZone(serverTime, time_zone, "HH:mm")
  }

  // update the selectedCityValue and the cookie
  handleCityChange({params}) {
    this.hideMenu()

    const {city} = params
    this.selectedCityValue = city

    document.cookie = `time_zone_selector_component=${city.name}; path=/`

  }

  // Display the Menu with all the cities
  showMenu() {
    this.menuOverlayTarget.style.display = "block"
    this.menuTarget.classList.add("time-zone-selector-component__container-menu-selected-clock__display")
  }

  // Hide the Menu with all the cities
  hideMenu() {
    this.menuOverlayTarget.style.display = "none"
    this.menuTarget.classList.remove("time-zone-selector-component__container-menu-selected-clock__display")
  }
}