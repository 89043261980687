import {Controller} from "stimulus";

let unfoldedShipmentPeriods = []

export default class extends Controller {
  static targets = ["soybeansFirstBid", "soybeansFirstOffer", "soybeansButton", "soybeansOffers", "soybeansBids", "shipmentPeriodButton"]

  shipmentPeriodButtonTargetConnected() {
    let shipmentPeriod = this.shipmentPeriodDescription()
    if (unfoldedShipmentPeriods.includes(shipmentPeriod))  this.toggleAction()
  }

  toggle() {
    // TODO: store expanded state between refresh
    let shipmentPeriod = this.shipmentPeriodDescription()

    if (unfoldedShipmentPeriods.includes(shipmentPeriod)) {
      unfoldedShipmentPeriods = unfoldedShipmentPeriods.filter(data => data.name !== shipmentPeriod)
    } else {
      unfoldedShipmentPeriods.push(shipmentPeriod)
    }

    this.toggleAction()
  }

  shipmentPeriodDescription(){
    return this.shipmentPeriodButtonTarget.innerText
  }

  toggleAction() {
    const toggleClasses = JSON.parse(this.element.dataset.toggleClasses);
    const offers = this.hasSoybeansOffersTarget && this.soybeansOffersTarget
    const bids = this.hasSoybeansBidsTarget && this.soybeansBidsTarget

    if (this.hasSoybeansFirstBidTarget) {
      this.soybeansFirstBidTarget.classList.toggle("trading-soybeans__first_bid_background")
    }

    if (this.hasSoybeansFirstOfferTarget) {
      this.soybeansFirstOfferTarget.classList.toggle("trading-soybeans__first_offer_background")
    }

    if (this.hasSoybeansButtonTarget) {
      this.soybeansButtonTarget.classList.toggle("btn-soybean-icon-down")
    }

    toggleClasses.forEach(toggleClass => {
      bids.classList.toggle(toggleClass);
      offers.classList.toggle(toggleClass);
    }, this);
  }
}
