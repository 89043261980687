import { Controller } from "stimulus"
import { uniqueId } from 'lodash'

import green from '../user_presence_status/green_10px.png'
import orange from '../user_presence_status/orange_10px.png'
import grey from '../user_presence_status/grey_10px.png'

const RANDOM_ID = _.uniqueId('random_')

export default class extends Controller {
  static targets = ["icon", "text"]
  static values = {userId: String, userStatus: String}

  iconTargetConnected () {
    this.iconTarget.style.backgroundImage = `url(${grey})`;
  }

  textTargetConnected () {
    this.textTarget.innerHTML = 'offline';
  }

  connect () {
    if (this.hasUserIdValue) {
      App[`user_${this.userIdValue}_rand_${RANDOM_ID}`] = App.cable.subscriptions.create({
          channel: "ActivitySupervisionChannel",
          id: this.userIdValue
        }, {
          received: (userUpdate) => {
            this.activityIcon(userUpdate.status)
          }
        }
      )
    }
  }

  activityIcon (status) {
    const activityMap = {
      offline: {iconPath: grey, text: 'offline'},
      idle: {iconPath: orange, text: 'idle'},
      online: {iconPath: green, text: 'online'}
    }

    const {iconPath, text} = activityMap[status];
    if (this.hasIconTarget) this.iconTarget.style.backgroundImage = `url(${iconPath})`;
    if (this.hasTextTarget) this.textTarget.innerHTML = text;
  }

  disconnect () {
    App[`user_${this.userIdValue}_rand_${RANDOM_ID}`].unsubscribe()
  }
}