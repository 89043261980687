import consumer from "./consumer"
import refreshTradingScreen from "./utils/refresh_trading_screen";
import {Modal} from 'bootstrap'
import MicroModal from "micromodal";
import {Api} from "k-utils-js";
// It may be nice to have the commodities array generated by erb evaluation.
const commodities = ["crystal_sugar150_br", "raw_sugar_th_hipol", "exotic_sugar", "soybeans", "raw_sugar_br"]

let requestTimeout = {};

commodities.forEach((commodity) => {

  consumer.subscriptions.create({
      channel: "IndividualTradingChannel",
      commodity: commodity,
      id: AppInfo.currentUserId
    }, {
      // Called once when the subscription is created.
      initialized() {
        // console.log(`TradingChannel ${commodity} initialized`)
      },

      // Called when the subscription is ready for use on the server.
      connected() {
        // console.log(`TradingChannel ${commodity} connected`)
      },

      // Called when the WebSocket connection is closed.
      disconnected() {
        // console.log(`TradingChannel ${commodity} disconnected`)
      },

      // Called when the subscription is rejected by the server.
      rejected() {
        //console.log(`TradingChannel ${commodity} rejected`)
      },
      showBookConfirmationModal(interestId) {
        let bookedShowUrl = `/interests/${commodity}/${interestId}/book_confirmation`;

        if(commodity === 'raw_sugar_th_hipol' || commodity === 'exotic_sugar') {
          bookedShowUrl = `/admin/${commodity}_interests/${interestId}/book_confirmation`;
        }

        const modal = new Modal(document.getElementById('interest-modal'), {
          keyboard: false
        })

        const relatedTarget = document.createElement("div")
        relatedTarget.setAttribute("data-modal-href", bookedShowUrl)

        modal.show(relatedTarget) // display modal with the relatedTarget Content (view booked_show)
      },

      received(data) {
        const interestId = data.interest_id


        // Add a setTimeOut to close the previous modal that has not yet had time to close
        if (location.pathname.includes(commodity) && data.type === "TRADING_INTEREST_SHOW_BOOK_ALERT") {
          let refresher = null
          refresher = setInterval(() => {
            let modal = document.getElementById('interest-modal');
            let modalLarge = document.getElementById('interest-modal-large');
            let modalDisplayed = modal?.classList.contains('show');
            let modalLargeDisplayed = modalLarge?.classList.contains('show');

            if (!modalDisplayed && !modalLargeDisplayed) {
              clearInterval(refresher)
              this.showBookConfirmationModal(interestId)
            }

          }, 500)

          setTimeout(() => {
            clearInterval(refresher)
          }, 10000)
        }
        // DISPLAY THE POPUP SCREEN (ONLY IF ANOTHER MODAL IS NOT ALREADY OPENED)

      }
    }
  )
});