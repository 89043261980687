import { Controller } from "stimulus"
import Mousetrap from 'mousetrap'

export default class extends Controller {
  static targets = ['activeTab']

  connect () {
    Mousetrap.bind(['ctrl+w'], (event) => {
      event.preventDefault()
      this.closeTab()
    });
  }


  closeTab () {
    if (!this.hasActiveTabTarget) return;

    const formLink = this.activeTabTarget.querySelector('form');
    if (formLink) {
      formLink.submit();
    }
  }
}
