import { Controller } from "@hotwired/stimulus"
import * as bootstrap from "bootstrap"
import _ from 'lodash'

export default class extends Controller {
  static targets = ['exportModal']

  connect() {
    let exportModal = new bootstrap.Modal(this.exportModalTarget);

    this.exportModalTarget.addEventListener('show.bs.modal', (event) => {
      document.getElementById('selectWarning').style.display = 'none';
      _.each(event.target.querySelectorAll("input[type='checkbox']"), (item, index) => {
        item.checked = true;
      });
    });

    _.each(this.exportModalTarget.querySelectorAll('form'), (item, index) => {
      item.addEventListener('submit', (event) => {
        event.preventDefault();
        if (this.exportModalTarget.querySelectorAll("input[type='checkbox']:checked").length == 0) {
          document.getElementById('selectWarning').style.display = 'block';
          item.disabled = 'disabled';
        } else {
          document.getElementById('selectWarning').style.display = 'none';
          item.submit();
        }
      });
    });
  }

  disconnect() {
    //
  }
}
