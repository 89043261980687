import consumer from "./consumer"
import refreshTradingScreen from "./utils/refresh_trading_screen";
import {Modal} from 'bootstrap'
import MicroModal from "micromodal";
import {Api} from "k-utils-js";
// It may be nice to have the commodities array generated by erb evaluation.
const commodities = ["crystal_sugar150_br", "raw_sugar_th_hipol", "exotic_sugar", "soybeans", "raw_sugar_br"]

let requestTimeout = {};

commodities.forEach((commodity) => {

  consumer.subscriptions.create({
      channel: "TradingChannel",
      commodity: commodity
    }, {
      // Called once when the subscription is created.
      initialized() {
        // console.log(`TradingChannel ${commodity} initialized`)
      },

      // Called when the subscription is ready for use on the server.
      connected() {
        // console.log(`TradingChannel ${commodity} connected`)
      },

      // Called when the WebSocket connection is closed.
      disconnected() {
        // console.log(`TradingChannel ${commodity} disconnected`)
      },

      // Called when the subscription is rejected by the server.
      rejected() {
        //console.log(`TradingChannel ${commodity} rejected`)
      },

      received(data) {
        // 1. REFRESH THE SCREEN
        if (location.pathname.includes(commodity)) {
          //console.log(`TradingChannel ${commodity} DATA RECEIVED`)

          // We wanted to prevent overloading the server:
          // because many events can occur almost at the same time,
          // we don't send the request to refresh the screen immediately,
          // instead we set a timeout and only after it expires we send the request,
          // every new attempt to send a request  before the timeout expires
          // clears the timeout of the precedent attempt. So for example, if  3 attempts happen
          // too close to each other only the last attempt will be able to send the request.
          clearTimeout(requestTimeout[commodity]);
          requestTimeout[commodity] = setTimeout(() => {
            refreshTradingScreen({commodity, onErrorMessage: `Error TradingChannel ${commodity}`})
          }, 500)

        }
      }
    }
  )
});