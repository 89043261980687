//source: https://gist.github.com/Intrepidd/ac68cb7dfd17d422374807efb6bf2f42
import { navigator } from '@hotwired/turbo'
import { Controller } from 'stimulus'
import { useMutation } from 'stimulus-use'

export default class extends Controller {
  connect () {
    useMutation(this, { attributes: true })
  }

  mutate (entries) {
    entries.forEach((mutation) => {
      if (mutation.type === 'attributes' && mutation.attributeName === 'src') {
        const src = this.element.getAttribute('src')
        if (src != null) {
          navigator.history.push(new URL(src))
        }
      }
    })
  }
}