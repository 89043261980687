export default function ({ commodity, errorMessage }) {
  let url = "";

  if (commodity === 'raw_sugar_th_hipol' || commodity === 'exotic_sugar' || commodity === 'raw_sugar_br' || commodity === 'crystal_sugar150_br') {
    url = `/admin/${commodity}_interests`
  } else {
    url = `/interests/${commodity}`
  }

  const onSuccess = function ({ data: { html } }) {
    const tradingScreen = document.getElementById('trading-screen');
    tradingScreen.innerHTML = html
  };

  const onError = function (data) {
    console.log(errorMessage);
    console.log(data);
  };

  Api.sendRequest({
    url: url,
    data: {},
    method: 'GET',
    onSuccess: onSuccess,
    onError: onError,
    delay: false
  });
}
